import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Colors } from 'constants/Colors';
import { ReactComponent as MobileLogo } from 'images/main-logo.svg';
import { ReactComponent as MenuIcon } from 'images/menu-icon.svg';
import { ReactComponent as TelegramIcon } from 'images/telegram.svg';
import { ReactComponent as TwitterIcon } from 'images/twitter.svg';
import { ReactComponent as MediumIcon } from 'images/medium.svg';
import useIsMobile from 'hooks/useIsMobile';

const TopBar = () => {
  const history = useHistory();
  const [openMenu, setOpenMenu] = useState(false);
  const [scrollY, setScrollY] = useState(0);
  const isMobile = useIsMobile();
  const mobileChangeBack = React.useMemo(() => isMobile && scrollY > 100, [isMobile, scrollY]);

  const [activeSection, setActiveSection] = useState('home');

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setScrollY(scrollPosition);
      const sections: any = document.querySelectorAll('#root>div>section');

      sections.forEach(section => {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.offsetHeight;

        if (
          scrollPosition >= sectionTop - 50 && // Adjust the offset as needed
          scrollPosition < sectionTop + sectionHeight - 50 // Adjust the offset as needed
        ) {
          setActiveSection(section.id);
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleUrlAnchorChange = () => {
      const sectionFromUrl = window.location.hash.substr(1);
      setActiveSection(sectionFromUrl);
    };

    window.addEventListener('hashchange', handleUrlAnchorChange);
    return () => {
      window.removeEventListener('hashchange', handleUrlAnchorChange);
    };
  }, []);

  return (
    <TopBarPage style={mobileChangeBack ? { background: '#aaaa' } : {}}>
      <Container>
        {isMobile ? (
          <MobileLogo onClick={() => history.push('/')} style={{ cursor: 'pointer' }} width={40} height={40} />
        ) : (
          <FlexStart>
            <MobileLogo
              onClick={() => history.push('/')}
              style={{ cursor: 'pointer', marginRight: '8px' }}
              width={40}
            />
            <h3 style={{ color: Colors.black, fontWeight: 'bold' }}>SPHYNX</h3>
            <h3 style={{ color: Colors.textGray1 }}>LABS</h3>
          </FlexStart>
        )}
        <RowBetween>
          {!isMobile && (
            <>
              <TextMenu
                style={{
                  display: 'block',
                  height: '80px',
                  lineHeight: '40px',
                  padding: '20px 8px',
                  color: activeSection === 'home' ? Colors.purple : Colors.black,
                }}
                href="#home"
                color={Colors.black}
              >
                Home
              </TextMenu>
              <TextMenu
                style={{
                  display: 'block',
                  height: '80px',
                  lineHeight: '40px',
                  padding: '20px 8px',
                  color: activeSection === 'all-in-one-defi' ? Colors.purple : Colors.black,
                }}
                href="#all-in-one-defi"
                color={Colors.black}
              >
                All-in-One-Defi
              </TextMenu>
              <TextMenu
                style={{
                  display: 'block',
                  height: '80px',
                  lineHeight: '40px',
                  padding: '20px 8px',
                  color: activeSection === 'card-crypto' ? Colors.purple : Colors.black,
                }}
                href="#card-crypto"
                color={Colors.black}
              >
                Crypto Card
              </TextMenu>
              <TextMenu
                style={{
                  display: 'block',
                  height: '80px',
                  lineHeight: '40px',
                  padding: '20px 8px',
                  color: activeSection === 'spay' ? Colors.purple : Colors.black,
                }}
                href="#spay"
                color={Colors.black}
              >
                Spay
              </TextMenu>
              <TextMenu
                style={{
                  display: 'block',
                  height: '80px',
                  lineHeight: '40px',
                  padding: '20px 8px',
                  color: activeSection === 'blockchain-service' ? Colors.purple : Colors.black,
                }}
                href="#blockchain-service"
                color={Colors.black}
              >
                Blockchain Development Service
              </TextMenu>
              {/* <TextMenu
                style={{
                  display: 'block',
                  height: '80px',
                  lineHeight: '40px',
                  padding: '20px 8px',
                  color: activeSection === 'contact-us' ? Colors.purple : Colors.black,
                }}
                href="#contact-us"
                color={Colors.black}
              >
                Contact Us
              </TextMenu> */}
            </>
          )}
        </RowBetween>
        {!isMobile && (
          <FlexStart style={{ gap: '12px' }}>
            <IconWrapper onClick={() => window.open('https://t.me/sphynxlabs')}>
              <TelegramIcon width={16} height={16} color={Colors.white} />
            </IconWrapper>
            <IconWrapper onClick={() => window.open('https://sphynxlabs.medium.com/')}>
              <MediumIcon width={16} height={16} color={Colors.white} />
            </IconWrapper>
            <IconWrapper onClick={() => window.open('https://twitter.com/SphynxLabs')}>
              <TwitterIcon width={16} height={16} color={Colors.white} />
            </IconWrapper>
          </FlexStart>
        )}
        {isMobile && (
          <>
            <MenuWrapper>
              <MenuIcon
                onClick={() => setOpenMenu(!openMenu)}
                style={{ cursor: 'pointer' }}
                width={40}
                height={40}
                color={Colors.indigo}
              />
              {openMenu && (
                <MenuListContainer>
                  <TextMenu
                    style={{
                      display: 'block',
                      color: activeSection === 'home' ? Colors.purple : Colors.black,
                    }}
                    href="#home"
                    onClick={() => setOpenMenu(false)}
                    color={Colors.black}
                  >
                    Home
                  </TextMenu>
                  <TextMenu
                    style={{
                      display: 'block',
                      color: activeSection === 'all-in-one-defi' ? Colors.purple : Colors.black,
                    }}
                    href="#all-in-one-defi"
                    onClick={() => setOpenMenu(false)}
                    color={Colors.black}
                  >
                    All-in-One-Defi
                  </TextMenu>
                  <TextMenu
                    style={{
                      display: 'block',
                      color: activeSection === 'visa-crypto' ? Colors.purple : Colors.black,
                    }}
                    href="#visa-crypto"
                    onClick={() => setOpenMenu(false)}
                    color={Colors.black}
                  >
                    Visa Crypto Card
                  </TextMenu>
                  <TextMenu
                    style={{
                      display: 'block',
                      color: activeSection === 'spay' ? Colors.purple : Colors.black,
                    }}
                    href="#spay"
                    onClick={() => setOpenMenu(false)}
                    color={Colors.black}
                  >
                    Spay
                  </TextMenu>
                  <TextMenu
                    style={{
                      display: 'block',
                      color: activeSection === 'blockchain-service' ? Colors.purple : Colors.black,
                    }}
                    href="#blockchain-service"
                    onClick={() => setOpenMenu(false)}
                    color={Colors.black}
                  >
                    Blockchain Development Service
                  </TextMenu>
                  {/* <TextMenu
                    style={{
                      display: 'block',
                      color: activeSection === 'contact-us' ? Colors.purple : Colors.black,
                    }}
                    href="#contact-us"
                    onClick={() => setOpenMenu(false)}
                    color={Colors.black}
                  >
                    Contact Us
                  </TextMenu> */}
                  <FlexStart style={{ gap: '12px', marginTop: '20px' }}>
                    <IconWrapper onClick={() => window.open('https://t.me/sphynxlabs')}>
                      <TelegramIcon width={16} height={16} color={Colors.white} />
                    </IconWrapper>
                    <IconWrapper onClick={() => window.open('https://sphynxlabs.medium.com/')}>
                      <MediumIcon width={16} height={16} color={Colors.white} />
                    </IconWrapper>
                    <IconWrapper onClick={() => window.open('https://twitter.com/SphynxLabs')}>
                      <TwitterIcon width={16} height={16} color={Colors.white} />
                    </IconWrapper>
                  </FlexStart>
                </MenuListContainer>
              )}
            </MenuWrapper>
          </>
        )}
      </Container>
    </TopBarPage>
  );
};

export default TopBar;

const TopBarPage = styled.header`
  position: fixed;
  z-index: 12;
  width: 100%;
  background: ${Colors.white};
  height: 81px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Container = styled.div`
  width: 100%;
  // max-width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
  position: relative;
`;
const Text = styled.p<{ size: string; color: string; bold: string; isHover?: boolean }>`
  font-weight: ${({ bold }) => bold};
  font-size: ${({ size }) => size};
  color: ${({ color }) => color};
  cursor: pointer;
  &:hover {
    opacity: ${({ isHover }) => (isHover ? '0.8' : '1')};
  }
`;
const TextMenu = styled.a`
  font-weight: 600;
  font-size: 16px;
  line-height: 35px;
  cursor: pointer;
  color: ${Colors.black};
  text-decoration: none;
  &:hover {
    opacity: 0.8;
  }
  &:link {
    color: ${Colors.purple};
  }
`;
const RowBetween = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;
const WhiteButton = styled.button`
  padding: 8px 12px;
  border-radius: 6px;
  background: ${Colors.white};
  border: none;
  font-weight: 600;
  font-size: 14px;
  color: ${Colors.textDark};
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;
const MenuWrapper = styled.div`
  position: relative;
`;

const MenuListContainer = styled.div`
  position: absolute;
  flex-direction: column;
  top: 40px;
  right: 5px;
  width: 300px;
  padding: 12px;
  background: ${Colors.utilsHover1};
  border-radius: 8px;
  height: 0;
  opacity: 0;
  overflow: hidden;
  padding: 0;
  transition: all 0.3s ease-in-out;
  :hover {
    background: ${Colors.utilsHover};
  }
  ${MenuWrapper}:hover & {
    opacity: 1;
    height: inherit;
    padding: 20px;
  }
  & a {
    font-size: 14px;
  }
`;
const IconWrapper = styled.div`
  cursor: pointer;
  padding: 8px;
  background: ${Colors.purple};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const FlexStart = styled.div`
  display: flex;
  align-items: center;
`;
