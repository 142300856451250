import React from 'react';
import TopBar from 'components/TopBar';
import BottomBar from 'components/BottomBar';

const MainLayout = props => {
  const { children } = props;

  return (
    <>
      <TopBar />
      {children}
      <BottomBar />
    </>
  );
};

export default MainLayout;
