import React, { useEffect } from 'react';
import { Route, useLocation } from 'react-router-dom';

const RouteWithLayout = props => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo({top: 0, left: 0})
  }, [pathname])

  const { layout: Layout, component: Component, ...rest } = props;

  return (
    <Route
      {...rest}
      render={matchProps => (
        <Layout>
          <Component {...matchProps} />
        </Layout>
      )}
    />
  );
};

export default RouteWithLayout;
