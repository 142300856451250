import { useEffect, useState } from 'react'

const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1080)

  const onResize = () => {
    setIsMobile(window.innerWidth < 1080)
  }

  useEffect(() => {
    window.addEventListener('resize', onResize)

    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [])

  return isMobile
}

export const useScreenWidth = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)

  const onResize = () => {
    setScreenWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', onResize)

    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [])

  return screenWidth
}

export default useIsMobile
