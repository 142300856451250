import { Colors } from 'constants/Colors';
import React from 'react';
import styled from 'styled-components';

const NotFound = () => {
  return (
    <HomePage>
      <Text>Not Found</Text>
    </HomePage>
  );
};

export default NotFound;

const HomePage = styled.div`
  background: ${Colors.dark};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;
const Text = styled.p`
  font-weight: 600;
  font-size: 30px;
  color: ${Colors.white};
`;
