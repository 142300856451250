import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from 'views/Home';
import NotFound from 'views/NotFound';
import RouteWithLayout from 'layouts/RouteWithLayout';
import MainLayout from 'layouts/MainLayout';

function App() {
  return (
    <Router>
      <Switch>
        <RouteWithLayout component={Home} exact strict layout={MainLayout} path="/" />
        <Route component={NotFound} />
      </Switch>
    </Router>
  );
}

export default App;
