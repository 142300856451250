import React from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as BottomLogoIcon } from 'images/main-logo.svg';
import { ReactComponent as TwitterIcon } from 'images/twitter.svg';
import { ReactComponent as TelegramIcon } from 'images/telegram.svg';
import { ReactComponent as InstagramIcon } from 'images/instagram.svg';
import { ReactComponent as MediumIcon } from 'images/medium.svg';
import { Grid, Col, Row } from 'react-styled-flexboxgrid';
import styled from 'styled-components';
import { Colors } from 'constants/Colors';

const BottomBar = () => {
  const history = useHistory();
  const utilitesName1 = [
    { label: 'MultiChain DEX', link: 'https://thesphynx.co/swap/bsc' },
    { label: 'Hyper Pool', link: 'https://thesphynx.co/hyper-pools/bsc' },
    { label: 'Bridge', link: 'https://thesphynx.co/bridge/bsc' },
    { label: 'NFT Marketplace', link: 'https://sphynxmarketplace.co' },
  ];
  const utilitesName2 = [
    { label: 'Real Time Charts', link: 'https://thesphynx.co/swap/bsc' },
    { label: 'Launch Pad', link: 'https://thesphynx.co/launchpad/bsc' },
    { label: 'Mobile App', link: 'https://play.google.com/store/apps/details?id=com.sphynx' },
    { label: 'SPay', link: 'https://sphynxpay.co' },
  ];
  const comingSoonNames = [
    'Futures/Perps Dex Integration',
    'Trading Bot',
    'BTC Hyperpools',
    'BTC Bridge',
    'Sphynx Chain',
    '25+ Blockchain Integrations',
    'RWA Marketplace',
    'NFT Rental Protocol',
    'Sphynx TV',
    'Wallet Extension',
    'and much more',
  ];
  const linkNames = [
    {
      label: 'Gitbook',
      link: 'https://sphynxlabs.gitbook.io/sphynx-labs/introduction/sphynx-labs-introduction',
    },
    {
      label: 'Telegram',
      link: 'https://t.me/sphynxlabs',
    },
    // {
    //   label: 'Audit',
    //   link: '',
    // },
  ];

  const openLink = _link => {
    if (_link) {
      if (_link.indexOf('http') !== -1) {
        window.open(_link, '_blank');
      } else {
        history.push(_link);
      }
    }
  };

  return (
    <BottomPage style={{ position: 'relative' }}>
      <div style={{ backdropFilter: 'blur(6px)' }}>
        <Container>
          <Grid style={{ width: '100%', borderBottom: '1px solid #aaa3', paddingBottom: '40px' }}>
            <Text size="16px" bold="600" color={Colors.purple}>
              COMMUNITY
            </Text>
            <Text size="36px" bold="600" color={Colors.black}>
              Latest SphynxLabs News
            </Text>
            <FlexColumnSocial>
              <FlexRowStart>
                <a href="https://twitter.com/SphynxLabs" target="_blank" rel="noopener noreferrer">
                  <IconWrapper>
                    <TwitterIcon width={18} height={18} />
                  </IconWrapper>
                </a>
                <a href="https://t.me/sphynxlabs" target="_blank" rel="noopener noreferrer">
                  <IconWrapper>
                    <TelegramIcon width={18} height={18} />
                  </IconWrapper>
                </a>
                <a href="https://www.instagram.com/sphynxlabs/" target="_blank" rel="noopener noreferrer">
                  <IconWrapper>
                    <InstagramIcon width={18} height={18} />
                  </IconWrapper>
                </a>
                <a href="https://sphynxlabs.medium.com/" target="_blank" rel="noopener noreferrer">
                  <IconWrapper>
                    <MediumIcon width={18} height={18} />
                  </IconWrapper>
                </a>
              </FlexRowStart>
            </FlexColumnSocial>
          </Grid>
          <Grid style={{ width: '100%' }}>
            <Row>
              <Col xs={12} sm={6} md={6} lg={4}>
                <FlexColumn>
                  <SectionWrapper>
                    <FlexColumn>
                      <Text size="24px" bold="600" color={Colors.black}>
                        Utilities
                      </Text>
                      {utilitesName1.map((data, index) => (
                        <Text
                          onClick={() => openLink(data.link)}
                          key={index}
                          size="16px"
                          bold="400"
                          isHover
                          color={Colors.black}
                        >
                          {data.label}
                        </Text>
                      ))}
                    </FlexColumn>
                    <FlexColumn>
                      <Space />
                      {utilitesName2.map((data, index) => (
                        <Text
                          onClick={() => openLink(data.link)}
                          key={index}
                          size="16px"
                          bold="400"
                          isHover
                          color={Colors.black}
                        >
                          {data.label}
                        </Text>
                      ))}
                    </FlexColumn>
                  </SectionWrapper>
                </FlexColumn>
              </Col>
              <Col xs={12} sm={6} md={6} lg={4}>
                <SectionWrapper>
                  <FlexColumn>
                    <Text size="24px" bold="600" color={Colors.black}>
                      2024 Road Map
                    </Text>
                    {comingSoonNames.map((title, index) => (
                      <Text key={index} size="16px" bold="400" isHover color={Colors.black}>
                        {title}
                      </Text>
                    ))}
                  </FlexColumn>
                  <FlexColumn>
                    <Text size="24px" bold="600" color={Colors.black}>
                      Links
                    </Text>
                    {linkNames.map((data, index) => (
                      <Text
                        onClick={() => {
                          if (data?.link) {
                            window.open(data?.link, '_blank');
                          }
                        }}
                        key={index}
                        size="16px"
                        bold="400"
                        isHover
                        color={Colors.black}
                      >
                        {data.label}
                      </Text>
                    ))}
                  </FlexColumn>
                </SectionWrapper>
              </Col>
              <FlexColumnSocial>
                <FlexRowStart>
                  <a href="https://twitter.com/SphynxLabs" target="_blank" rel="noopener noreferrer">
                    <IconWrapper>
                      <TwitterIcon width={18} height={18} />
                    </IconWrapper>
                  </a>
                  <a href="https://t.me/sphynxlabs" target="_blank" rel="noopener noreferrer">
                    <IconWrapper>
                      <TelegramIcon width={18} height={18} />
                    </IconWrapper>
                  </a>
                  <a href="https://www.instagram.com/sphynxlabs/" target="_blank" rel="noopener noreferrer">
                    <IconWrapper>
                      <InstagramIcon width={18} height={18} />
                    </IconWrapper>
                  </a>
                  <a href="https://sphynxlabs.medium.com/" target="_blank" rel="noopener noreferrer">
                    <IconWrapper>
                      <MediumIcon width={18} height={18} />
                    </IconWrapper>
                  </a>
                </FlexRowStart>
              </FlexColumnSocial>
              <Col xs={12} lg={12}>
                <FlexStart style={{ height: '42px', margin: '20px 12px', marginTop: '90px', justifyContent: 'center' }}>
                  <BottomLogoIcon style={{ marginRight: '8px' }} width={40} />
                  <h3 style={{ color: Colors.black, fontWeight: 'bold' }}>SPHYNX</h3>
                  <h3 style={{ color: Colors.textGray1 }}>LABS</h3>
                </FlexStart>
                <Text
                  size="16px"
                  bold="400"
                  color={Colors.textGray}
                  style={{ textAlign: 'center' }}
                >
                  © 2024 SphynxLabs
                </Text>
              </Col>
            </Row>
          </Grid>
        </Container>
      </div>
      <BottomBackground />
    </BottomPage>
  );
};

export default BottomBar;

const BottomPage = styled.div`
  position: relative;
`;
const BottomBackground = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  z-index: -1;
  height: 100%;
  background: ${Colors.white};
`;
const Container = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 60px 20px;
  @media (max-width: 768px) {
    padding: 40px 0px;
  }
`;
const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 22px;
`;
const FlexColumnSocial = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-top: 24px;
  justify-content: flex-end;
  gap: 22px;
  @media (max-width: 768px) {
    align-items: center;
  }
`;
const Text = styled.p<{ size: string; color: string; bold: string; isHover?: boolean }>`
  font-weight: ${({ bold }) => bold};
  font-size: ${({ size }) => size};
  color: ${({ color }) => color};
  cursor: pointer;
  &:hover {
    opacity: ${({ isHover }) => (isHover ? '0.8' : '1')};
  }
`;
const FlexRowStart = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
`;
const IconWrapper = styled.div`
  cursor: pointer;
  padding: 9px;
  background: ${Colors.socialBgColor};
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const SectionWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  padding-top: 24px;
  gap: 24px;
`;
const Space = styled.div`
  padding: 14px;
`;
const FlexStart = styled.div`
  display: flex;
  align-items: center;
`;
